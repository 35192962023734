import React from 'react'

import styles from "./styles.module.css";

const ContactCard = ({contato,onClick}) => {
  return (
    <div onClick={onClick} className={styles.container}>
       <p className={styles.contactNameText}>{contato.nome}</p>
       
    </div>
  )
}

export default ContactCard