import React from 'react'
import styles from "./styles.module.css";
import { FaWhatsapp,FaInstagram,FaFacebook, FaChrome } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const ModalCadastro = ({contato,setModalVisible,onWhatsAppPress,onInstagramPress,onFacebookPress,onWebSitePress}) => {
    return (
        <div className={styles.modalOverlay} onClick={()=>setModalVisible(false)}>
            <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
                 <div className={styles.titleContainer}>
                    <MdClose className={styles.close} onClick={()=>setModalVisible(false)}  size={22} />
                </div>
                 <div className={styles.content}>
                    <span className={styles.nome}>{contato.nome}</span>
                    <div className={styles.socialArea}>
                        {contato.telefone.length>0&&<div className={styles.socialButton} onClick={()=>onWhatsAppPress(contato.telefone)}>
                             <FaWhatsapp className={styles.socialIcon} size={30} />
                             <span className={styles.socialNome}>WhatsApp</span>
                        </div>}
                        {contato.instagram.length>0&&<div className={styles.socialButton} onClick={()=>onInstagramPress(contato.instagram)}>
                             <FaInstagram className={styles.socialIcon} size={30} />
                             <span className={styles.socialNome}>Instagram</span>
                        </div>}
                        {contato.facebook.length>0&&<div className={styles.socialButton} onClick={()=>onFacebookPress(contato.facebook)}>
                             <FaFacebook className={styles.socialIcon} size={30} />
                             <span className={styles.socialNome}>Facebook</span>
                        </div>}
                        {contato.website.length>0&&<div className={styles.socialButton} onClick={()=>onWebSitePress(contato.website)}>
                             <FaChrome className={styles.socialIcon} size={30} />
                             <span className={styles.socialNome}>WebSite</span>
                        </div>}
                    </div>
                 </div>
                 
            </div>
        </div>
      )
}

export default ModalCadastro